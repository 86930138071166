.date-range-picker {
  position: relative;
  width: 100%;
}

.date-range-picker h2 {
  margin-bottom: 10px;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 20px;
  color: #000;
  margin-right: 10px;
}

.daterangepicker td.leaveDatesTaken {
  background-color: #e53935 !important;
  color: #fff !important;
}

.daterangepicker td.eventDate {
  background-color: #000;
  color: #fff;
}

.daterangepicker td.ends {
  background-color: rgb(242, 240, 240);
}

.daterangepicker td.disabled {
  text-decoration: none; 
  
}