#student-detail-page-schedule {
    position: relative;
    min-width: 900px;
    #schedule-items {
      position: absolute;
      .schedule-session-cell {
        &:hover {
          cursor: pointer;
          height: auto;
          z-index: 2147483647;
          min-height: 80px;
          box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
        }
      }
    }
    .session-period-hours {
      writing-mode: vertical-lr;
      font-weight: 200;
    }
    .conflict {
      background-color: darkorchid !important;
    }
    .schedule-session-cell {
      background-color: chocolate;
      .cell-item {
        padding-bottom: 10px;
      }
    }
    #Monday,
    #Tuesday,
    #Wednesday,
    #Thursday,
    #Friday,
    #Saturday,
    #Sunday {
      width: 100%;
      min-width: 130px;
      text-align: center;
    }
    #Monday {
      order: 0;
    }
    #Tuesday {
      order: 1;
    }
  
    #Wednesday {
      order: 2;
    }
  
    #Thursday {
      order: 3;
    }
  
    #Friday {
      order: 4;
    }
  
    #Saturday {
      order: 5;
    }
  
    #Sunday {
      order: 6;
    }
  }
  
  @media print {
    @page {
      size: A4 landscape;
    }
    html,
    body {
      zoom: 100%;
    }
    #student-detail-page-schedule {
        margin: 0 !important;
        padding: 0 !important;
    }
    .day-name-row {
      max-width: 100vw !important;
    }
    #schedule-items {
      zoom: 95%;
      width: 100vw !important;
      display: flex;
      font-size: 95%;
      a,
      a:visited {
        color: black;
      }
      .schedule-session-cell {
        width: auto !important;
        .cell-item {
          color: black;
          width: auto !important;
        }
      }
    }
  }
  