$cell-border: rgba(0, 0, 0, 0.56);
$out-of-range-bg-color: rgba(0, 0, 0, 0.1);
$calendar-border: rgba(0, 0, 0, 0.56) !default;

@import 'react-big-calendar/lib/sass/styles';

.rbc-month-view {
  border-top: none !important;
}

.rbc-month-header {
  .rbc-header {
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
    padding: 10px;
  }
}

.rbc-event {
  position: relative; 
}

.rbc-event.line::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-top: 2px solid rgb(0, 0, 0); 
  pointer-events: none;
  z-index: 99;           
  transform: rotate(-45deg); 
  transform-origin: top right;
  box-shadow: #333;
}

.rbc-event.line {
  box-shadow:  0px 4px 6px rgba(0, 0, 0, 0.2);
}
.rbc-event.star {
  box-shadow:  0px 4px 6px rgba(0, 0, 0, 0.2);
}

.rbc-event.star::after {
  content: "★";
  position: absolute;
  bottom: 3px; 
  right: 5px; 
  font-size: 20px; 
  color: gold;  
  z-index: 100; 
}

.rbc-date-cell {
  text-align: left;
  padding: 10px;
}

.rbc-button-link {
  pointer-events: none;
}

.rbc-agenda-time-cell {
  display: none;
 }
 .rbc-agenda-table .rbc-header:nth-child(2) {
  display: none;
}

.dark-calendar {
  .rbc-today {
    background-color: #333;
    color: #fff !important;
  }
  .rbc-now {
    color: #fff;
  }
}

.light-calendar {
  .rbc-today {
    background-color: #eaf6ff;
    color: #fff !important;
  }
  .rbc-now {
    color: #000;
  }
}

.light-district-calendar {
 .rbc-today {
  color: #000;
 }
}

@media (prefers-color-scheme: light) {
  .auto-calendar {
    .rbc-today {
      background-color: #eaf6ff;
      color: #fff !important;
    }
    .rbc-now {
      color: #000;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .auto-calendar {
    .rbc-today {
      background-color: #333;
      color: #fff !important;
    }
    .rbc-now {
      color: #fff;
    }
  }
  
}
