@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';

$light-grid-table-title: rgba(0, 0, 0, 0.6);
$dark-grid-table-title: rgba(255, 255, 255, 0.6);
$font-weight: 400;

$tab-item-light: rgba(0, 0, 0, 0.38);
$tab-item-dark: rgba(255, 255, 255, 0.38);
$tab-item-selected: 'primary !important';
$tab-font-weight: 500;

@media (prefers-color-scheme: light) {
  .grid-table-title-light {
    color: $light-grid-table-title;
    font-weight: $font-weight;
  }
  .grid-table-title-dark {
    color: $dark-grid-table-title;
    font-weight: $font-weight;
  }
  .grid-table-title- {
    color: $light-grid-table-title;
    font-weight: $font-weight;
  }
  .tab-item-light {
    color: $tab-item-light;
    font-weight: $tab-font-weight;
  }
  .tab-item-dark {
    color: $tab-item-dark;
    font-weight: $tab-font-weight;
  }
  .tab-item- {
    color: $tab-item-light;
    font-weight: $tab-font-weight;
  }
}

@media (prefers-color-scheme: dark) {
  .grid-table-title-dark {
    color: $dark-grid-table-title;
    font-weight: $font-weight;
  }
  .grid-table-title-light {
    color: $light-grid-table-title;
    font-weight: $font-weight;
  }
  .grid-table-title- {
    color: $dark-grid-table-title;
    font-weight: $font-weight;
  }
  .tab-item-light {
    color: $tab-item-light;
    font-weight: $tab-font-weight;
  }
  .tab-item-dark {
    color: $tab-item-dark;
    font-weight: $tab-font-weight;
  }
  .tab-item- {
    color: $tab-item-dark;
    font-weight: $tab-font-weight;
  }
}

.grid-table-title-value {
  font-weight: $font-weight;
}

.tab-item-selected {
  color: $tab-item-selected;
  font-weight: $tab-font-weight;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-size-14 {
  font-size: '14px !important';
}
